'use client';

import { IBreadcrumbTitles } from 'components/Breadcrumbs';
import { SortItemType, useSort } from '../../hooks/useSort';
import dynamic from 'next/dynamic';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import getSearchClient from 'server/repository/algolia/client';

import {
  FilterItemType,
  FilterTopic,
  filterItems,
  getFiltersList,
} from 'server/repository/algolia/filter/Filters';

import type { ISearchOverlay } from 'components/HeaderNavigation/components/MobileNavigation';
import type { ISocialMediaComponent } from 'components/SocialMedia/types';
import type { ICardItem } from 'components/Subcategory';
import type {
  DictionaryItem,
  ProductCategoryPage,
} from 'server/repository/hygraph/generated/graphqlTypes';
import type { ProductGridProduct } from 'types/Product';

import TemplateMapper from 'templates/TemplateMapper';
import { TrackingService } from 'service/tracking/trackingService';
import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';
import { ProductsService } from 'service/products/productsService';

const Breadcrumbs = dynamic(
  () => import('components/Breadcrumbs').then((m: any) => m.Breadcrumbs),
  { ssr: true },
) as any;

const ProductGrid = dynamic(
  () => import('components/ProductGrid').then((m: any) => m.ProductGrid),
  { ssr: true },
) as any;

const IntroText = dynamic(() => import('components/IntroText').then((m: any) => m.IntroText), {
  ssr: true,
}) as any;

const FilterMenu = dynamic(() => import('components/FilterMenu').then((m: any) => m.FilterMenu), {
  ssr: true,
}) as any;

const SliderCarousel = dynamic(
  () => import('components/SliderCarousel').then((m) => m.SliderCarousel),
  { ssr: true },
);

const SocialMedia = dynamic(() => import('components/SocialMedia').then((m) => m.SocialMedia), {
  ssr: true,
});

const Subcategory = dynamic(
  () => import('components/Subcategory').then((m: any) => m.Subcategory),
  { ssr: true },
) as any;

const SubbrandProducts = dynamic(
  () => import('components/SubbrandProducts').then((m: any) => m.SubbrandProducts),
  { ssr: true },
) as any;

const Spacer = dynamic(() => import('components/Spacer').then((m) => m.Spacer), {
  ssr: true,
});

const Container: React.FC<PropsWithChildren> = ({ children }) => (
  <div className="flex flex-col items-center mx-auto relative md:mx-0">{children}</div>
);

function ClientProductsCategorySlugPage({
  productCategoryPage,
  learnMoreText,
  productsText,
  followUsData,
  categoryProducts,
  searchItemLabels,
  breadcrumbsCMSData,
  searchOverlayData,
  difficultyCopy,
  recipeCopy,
  hideSubCategories,
  dynamicPageComponents,
  globalLabels,
}: {
  productCategoryPage: ProductCategoryPage;
  learnMoreText: DictionaryItem;
  productsText: DictionaryItem;
  followUsData: ISocialMediaComponent;
  categoryProducts: ProductGridProduct[];
  searchItemLabels: Record<string, string>;
  breadcrumbsCMSData: IBreadcrumbTitles;
  searchOverlayData: ISearchOverlay;
  difficultyCopy: Record<string, string>;
  globalLabels: Record<string, string>;
  recipeCopy: DictionaryItem[];
  hideSubCategories: boolean;
  dynamicPageComponents: any;
}) {
  const { pathTranslations } = useGlobalContext();
  const { getAvailableSortMethods, sortItems } = useSort(SortItemType.PRODUCT);
  const [currentSortMethod, setCurrentSortMethod] = useState<string>(
    () => getAvailableSortMethods()[0],
  );

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [filteredProducts, setFilteredProducts] = useState<ProductGridProduct[]>(
    () => sortItems([...categoryProducts], currentSortMethod) as ProductGridProduct[],
  );
  const [currentFilters, setCurrentFilters] = useState<FilterTopic[]>(() =>
    getFiltersList(FilterItemType.PRODUCT, filteredProducts, searchItemLabels),
  );

  const filterProducts = (newFilters: FilterTopic[]) => {
    setCurrentFilters(newFilters);
    const products = filterItems(FilterItemType.PRODUCT, categoryProducts, newFilters);
    setFilteredProducts(sortItems(products, currentSortMethod) as ProductGridProduct[]);
  };

  const [searchOverlayDataExtended, setSearchOverlayDataExtended] = useState(searchOverlayData);

  useEffect(() => {
    if (!searchOverlayDataExtended.searchClient) {
      const algoliaClient = getSearchClient();
      setSearchOverlayDataExtended({
        ...searchOverlayDataExtended,
        searchClient: algoliaClient,
      });
    }
  }, [searchOverlayData]);

  useEffect(() => {
    setFilteredProducts(
      sortItems([...filteredProducts], currentSortMethod) as ProductGridProduct[],
    );
  }, [currentSortMethod]);

  const breadcrumbsCategories = [
    {
      slug: pathTranslations.products,
      displayName: breadcrumbsCMSData?.productsText?.value,
    },
  ];

  const heroCarouselData = productCategoryPage?.heroCarouselComponent;
  const introData = productCategoryPage?.introText;
  const categoryData = productCategoryPage;
  const subcategoryData = {
    subHeading: categoryData?.productSubcategorySuper || '',
    heading: categoryData?.productSubcategoryTitle || '',
  };

  const SubBrandWrapper = ({ subCategoryPage, containerPosition }: any) => {
    // return card object
    const subBrandCardsData = subCategoryPage.productCategories?.flatMap((productCategory: any) =>
      productCategory.products?.map((product: any) => ({
        categoryId: subCategoryPage?.id,
        id: product?.id,
        category: productCategoryPage?.productCategoryName,
        subCategory: productCategoryPage?.productSubcategoryTitle,
        images: {
          desktop: product?.productImage?.desktop,
          mobile: product?.productImage?.mobile,
          mimeType: product?.mimeType,
        },
        title: product?.productTitle,
        link: {
          url: `${pathTranslations.product}${product?.slug || ''}`,
          displayName: learnMoreText.value,
        },
      })),
    );

    const titles = {
      heading: subCategoryPage.subcategoryPageName,
      subHeading: subCategoryPage.shortDescription,
    };
    const buttonLink = {
      displayName: learnMoreText.value,
      url: `${pathTranslations.productsSubCategory}${categoryData?.slug}/${subCategoryPage?.slug}`,
    };
    const desktopImage = {
      desktop: subCategoryPage?.keyImage?.asset?.[0]?.desktop,
      mimeType: subCategoryPage?.keyImage?.asset?.[0]?.mimeType,
    };
    const mobileImage = {
      mobile: subCategoryPage?.keyImageMobile?.asset?.[0]?.mobile,
      mimeType: subCategoryPage?.keyImageMobile?.asset?.[0]?.mimeType,
    };
    const productTeaserCardClickContainer = (data?: any, position?: number) => {
      TrackingService.productTeaserCardClick(
        data?.title || 'na',
        position || 0,
        subCategoryPage.subcategoryPageName || 'na',
        containerPosition + 1 || 0,
        'carousel',
      );
    };

    const clickSeeSubcategoryProductContainer = () => {
      TrackingService.clickSeeSubcategoryProduct({
        category: categoryData.productCategoryName,
        subcategory: subCategoryPage.subcategoryPageName,
      });
    };

    if (subBrandCardsData?.length) {
      return (
        <>
          <SubbrandProducts
            cardData={subBrandCardsData}
            titles={titles}
            keyImage={desktopImage}
            keyImageMobile={mobileImage}
            logo={subCategoryPage?.logo?.asset?.[0]?.url}
            buttonLink={buttonLink}
            curveColor={subCategoryPage?.primarySubBrandColor?.hex}
            trackingEvents={{
              productTrackingEvent: productTeaserCardClickContainer,
              subcategoryTrackingEvent: clickSeeSubcategoryProductContainer,
            }}
          />
        </>
      );
    }
    return null;
  };
  const subcategoryCardsData = categoryData?.productSubcategoryPages
    ?.filter((subCategory: any) => subCategory.subBrand === false)
    // returns card object
    .map((subCategory: any) => ({
      productsAmount: `${ProductsService.getProductsAmount(subCategory)} ${
        productsText?.value || 'products'
      }`,
      id: subCategory?.id,
      subcategory: subCategory?.subcategoryPageName,
      categoryName: subCategory?.productCategoryPage?.productCategoryName,
      images: {
        desktop: subCategory?.keyImage?.asset?.[0]?.desktop,
        mobile: subCategory?.keyImage?.asset?.[0]?.mobile,
        mimeType: subCategory?.keyImage?.asset?.[0]?.mimeType,
      },
      title: subCategory?.subcategoryPageName,
      link: {
        url: `${pathTranslations.productsSubCategory}${categoryData?.slug}/${subCategory?.slug}`,
        displayName: '',
        openInNewTab: categoryData.link?.openInNewTab ?? false,
      },
    }));

  useEffect(() => {
    TrackingService.contentGroups({
      cg1: 'product categories',
      cg2: categoryData?.productCategoryName || '',
    });
  }, []);

  /**
   * Used to prepare the product data on the landing page for the click_see_product event
   */
  const clickSeeProductOnProductLandingPageContainer = (cardData: any) => {
    TrackingService.clickSeeProduct({
      name: cardData.title,
      category: cardData.category,
      subcategory: cardData.subCategory,
      tags: cardData?.metaKeywords || 'na',
      brand: cardData?.subbrand || 'Dr. Oetker',
      fillingQuantity: cardData?.fillingQuantity,
      filterCategory: TrackingService.getFilterCategoriesCommaSeparatedList(currentFilters) || 'na',
      filtersUsed: TrackingService.getFilterOptionsCommaSeparatedList(currentFilters) || 'na',
      sortingUsed: currentSortMethod,
    });
  };
  /**
   * Used to prepare the subproduct data on the landing page for the
   * clickSeeSubcategoryProduct event
   */
  const clickSeeSubcategoryProductOnProductLandingPageContainer = (cardData: any) => {
    TrackingService.clickSeeSubcategoryProduct({
      category: cardData.categoryName,
      subcategory: cardData.subcategory,
    });
  };
  return (
    <>
      <FilterMenu
        id="category-filter-menu"
        isOpen={showFilters}
        title={searchItemLabels.filters}
        totalResults={filteredProducts.length}
        onClose={() => setShowFilters(false)}
        onApplyFilters={(newFilters: FilterTopic[]) => filterProducts(newFilters)}
        filters={currentFilters}
        labels={searchItemLabels}
      />
      <>
        <div className="max-w-6xl mx-auto">
          <Breadcrumbs
            titles={breadcrumbsCMSData}
            pageName={productCategoryPage?.productCategoryName || ''}
            categories={breadcrumbsCategories}
          />
          <div className="overflow-hidden">
            {!heroCarouselData && <Spacer />}
            <Container>
              {heroCarouselData && (
                <>
                  <SliderCarousel
                    bannerMasterbrands={heroCarouselData?.bannerMasterbrands}
                    banners={heroCarouselData?.banners}
                    isForBanners
                    type="carousel"
                    watchVideoLabel={globalLabels.watch_video}
                  />
                  <Spacer height={50} />
                </>
              )}
              {introData && (
                <>
                  <IntroText
                    super={introData.super ?? ''}
                    title={introData.title ?? ''}
                    description={introData.description ?? ''}
                  />
                  <Spacer />
                </>
              )}
              {subcategoryCardsData && !hideSubCategories && (
                <Subcategory
                  titles={subcategoryData}
                  cardData={subcategoryCardsData as ICardItem[]}
                  subcategoryCardVariation="object"
                  trackingEvent={clickSeeSubcategoryProductOnProductLandingPageContainer}
                />
              )}
              {!hideSubCategories &&
                categoryData?.productSubcategoryPages
                  ?.filter((subCategory: any) => subCategory.subBrand === true)
                  .map((subCategoryPage: any, index: number) => (
                    <SubBrandWrapper
                      key={subCategoryPage.id ?? `subbrand-${index}`}
                      subCategoryPage={subCategoryPage}
                      containerPosition={index}
                    />
                  ))}
            </Container>
          </div>
          <ProductGrid
            id="subcategory-product-grid"
            heading={productCategoryPage?.productGridTitle || ''}
            products={filteredProducts}
            labels={searchItemLabels}
            currentSort={currentSortMethod}
            sortOptions={getAvailableSortMethods().map((m) => ({
              label: searchItemLabels[`sort_${m}`] || m,
              value: m,
            }))}
            onSortProducts={(sm: string) => setCurrentSortMethod(sm)}
            onShowFilters={() => setShowFilters(!showFilters)}
            currentFiltersCount={currentFilters.length}
            trackingEvent={clickSeeProductOnProductLandingPageContainer}
          />
          {dynamicPageComponents && (
            <TemplateMapper
              components={dynamicPageComponents}
              copy={[]}
              recipeCopy={recipeCopy}
              difficultyCopy={difficultyCopy}
              searchItemLabels={searchItemLabels}
              searchData={searchOverlayDataExtended}
            />
          )}
          {!!followUsData && (
            <SocialMedia
              id={followUsData.id}
              super={followUsData.super}
              title={followUsData.title}
              socialMedia={followUsData.socialMedia}
              middlegroundImage={followUsData.middlegroundImage}
              foregroundImage={followUsData.foregroundImage}
            />
          )}
        </div>
      </>
    </>
  );
}

export default ClientProductsCategorySlugPage;
