import RootLayoutClient from 'components/PageLevelComponents/ClientLayout';
import ClientProductsCategorySlugPage from 'components/PageLevelComponents/ClientProductsCategorySlugPage';
import type { ISocialMediaComponent } from 'components/SocialMedia/types';
import { LOCALE_DEFAULT } from 'config/config';
import { ProductsCategoryPageController } from 'server/controller/page/products/categoryPageController';
import {
  DictionaryItem,
  ProductCategoryPage,
} from 'server/repository/hygraph/generated/graphqlTypes';
import { ProductsCategoryPageResource } from 'server/resource/page/productsCategoryPageResource';

const RootPage = (props: ProductsCategoryPageResource) => {
  const {
    metaData,
    searchItemLabels,
    recipeCopy,
    breadcrumbsCMSData,
    footerNavData,
    footerImage,
    usercentricsIds,
    locale,
    searchOverlayData,
    countrySelectorData,
    newsletterData,
    contactUsUrl,
    difficultyCopy,
    getHeaderNav,
    getMobileNavData,
    popularSearchData,
    cardCarouselCMSData,
    recallData,
    learnMoreText,
    dynamicPageComponents,
    productsText,
    followUsData,
    categoryProducts,
    productCategoryPage,
    globalLabels,
    hideSubCategories,
    pathTranslations,
  } = props;

  return (
    <RootLayoutClient
      globalMetadata={metaData}
      getHeaderNav={getHeaderNav}
      getMobileNavData={getMobileNavData}
      popularSearchData={popularSearchData}
      footerNavData={footerNavData}
      footerImage={footerImage}
      usercentricsIds={usercentricsIds}
      locale={locale}
      searchOverlayData={searchOverlayData}
      countrySelectorData={countrySelectorData}
      newsletterData={newsletterData}
      contactUsUrl={contactUsUrl}
      difficultyCopy={difficultyCopy as Record<string, string>}
      cardCarouselCMSData={cardCarouselCMSData}
      recallData={recallData}
      pathTranslations={pathTranslations}
    >
      <ClientProductsCategorySlugPage
        key={productCategoryPage?.id}
        learnMoreText={learnMoreText as DictionaryItem}
        productsText={productsText as DictionaryItem}
        followUsData={followUsData as unknown as ISocialMediaComponent}
        categoryProducts={categoryProducts}
        productCategoryPage={productCategoryPage as ProductCategoryPage}
        breadcrumbsCMSData={breadcrumbsCMSData}
        recipeCopy={recipeCopy}
        searchItemLabels={searchItemLabels as Record<string, string>}
        searchOverlayData={searchOverlayData}
        difficultyCopy={difficultyCopy as Record<string, string>}
        dynamicPageComponents={dynamicPageComponents}
        globalLabels={globalLabels}
        hideSubCategories={hideSubCategories}
      />
    </RootLayoutClient>
  );
};
type GetServerSidePropsParams = {
  locale: string;
  params: {
    slug: string;
  };
  defaultLocale: string;
  resolvedUrl: string;
};

export const getServerSideProps = async ({
  locale,
  params,
  defaultLocale,
  resolvedUrl,
}: GetServerSidePropsParams) => {
  return ProductsCategoryPageController.getCachedCategoryPageData({
    resolvedUrl,
    locale: locale || defaultLocale || LOCALE_DEFAULT,
    slug: params.slug,
  });
};

export default RootPage;
